import { AntdModule } from 'projects/tools/src/lib/antd.module';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './login/login.component';
import { NavbarComponent } from './navbar/navbar.component';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'projects/fliprobot/src/app/shared/shared.module';
import { ToolsModule } from 'projects/tools/src/lib/tools.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [NavbarComponent, FooterComponent, LoginComponent],
  imports: [
    AntdModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    ToolsModule,
    TranslateModule,
  ],
  exports: [NavbarComponent, FooterComponent, LoginComponent],
})
export class LayoutModule {}
