import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AboutUsComponent } from './page/about-us/about-us.component';
import { ConfirmEmailComponent } from './page/confirm-email/confirm-email.component';
import { ContactUsComponent } from './page/contact-us/contact-us.component';
import { FlipToolsComponent } from './page/flip-tools/flip-tools.component';
import { HomeComponent } from './page/home/home.component';
import { LoginComponent } from './layout/login/login.component';
import { NavbarComponent } from './layout/navbar/navbar.component';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';
import { RedirectToBlogComponent } from './shared/components/redirect-to-blog/redirect-to-blog.component';
import { ResetPasswordComponent } from './page/reset-password/reset-password.component';
import { TrainingComponent } from './page/training/training.component';
import { UnlockCardComponent } from './page/unlock-card/unlock-card.component';
import { AdminGuard, AuthGuard } from 'projects/tools/src/lib/guards';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: '',
    component: NavbarComponent,
    children: [
      {
        path: 'about-us',
        component: AboutUsComponent,
      },

      {
        path: 'contact-us',
        component: ContactUsComponent,
      },
      {
        path: 'courses',
        loadChildren: () =>
          import('./curriculum/curriculum.module').then((m) => m.CurriculumModule),
      },
      {
        path: 'login',
        component: LoginComponent,
      },
      {
        path: 'player/projects',
        loadChildren: () => import('./project/project.module').then((m) => m.ProjectModule),
      },
      {
        path: 'teacher/training',
        component: TrainingComponent,
      },
      {
        path: 'tools',
        component: FlipToolsComponent,
      },
      {
        path: 'user',
        loadChildren: () => import('./user/user.module').then((m) => m.UserModule),
      },
    ],
  },
  {
    path: 'admin',
    canActivate: [AuthGuard, AdminGuard],
    canActivateChild: [AuthGuard],
    loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'confirm-email',
    component: ConfirmEmailComponent,
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
  },
  {
    path: 'unlock-card',
    component: UnlockCardComponent,
  },
  {
    path: '404',
    component: NavbarComponent,
    children: [
      {
        path: '',
        component: NotFoundComponent,
      },
    ],
  },
  {
    path: '**',
    component: RedirectToBlogComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'top',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
