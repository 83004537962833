import { NzMessageService } from 'ng-zorro-antd/message';
import {
  Component,
  OnInit,
  TemplateRef,
  NgZone,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { environment } from 'projects/fliprobot/src/environments/environment';
import { FacebookComponent } from '../../shared/components/facebook/facebook.component';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { GoogleComponent } from '../../shared/components/google/google.component';
import { HttpService, AuthService, ITokenBody } from 'projects/tools/src/lib/services';
import { NzModalService, NzModalRef } from 'ng-zorro-antd/modal';
import { ProjectDataSource } from 'projects/tools/src/lib/data/project.data';
import { Router } from '@angular/router';
import { tap, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less'],
})
export class LoginComponent implements OnInit {
  @Input() isModal: boolean;
  @Output() closeModel = new EventEmitter<boolean>();
  @ViewChild(FacebookComponent) facebook: FacebookComponent;
  @ViewChild(GoogleComponent) google: GoogleComponent;
  agreeTerms = false;
  baseUrl = environment.baseUrl;
  card: string;
  isLoading = false;
  isLoginPath: boolean;
  isPasswordVisible = false;
  isSignIn = true;
  resetPasswordForm: FormGroup;
  resetPasswordModal: NzModalRef;
  returnPath: string;
  signInForm: FormGroup;
  signUpForm: FormGroup;
  constructor(
    private auth: AuthService,
    private dataSource: ProjectDataSource,
    private fb: FormBuilder,
    private http: HttpService,
    private message: NzMessageService,
    private modal: NzModalService,
    private ngZone: NgZone,
    private router: Router,
  ) {}
  value = (value: string) => ({ value });
  matchValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { required: true };
    }
    return control.value !== this.signUpForm.controls.password.value
      ? { match: true, error: true }
      : {};
  };
  ngOnInit(): void {
    if (this.auth.loggedIn) {
      this.router.navigateByUrl('/');
    }
    this.returnPath = history.state.path || '/';
    this.isLoginPath = location.pathname === '/login';
    this.signInForm = this.fb.group({
      email: new FormControl(null, {
        validators: [Validators.required, Validators.email],
        updateOn: 'blur',
      }),
      password: new FormControl(null, {
        validators: [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(30),
          Validators.pattern('[a-zA-Z0-9]*'),
        ],
        updateOn: 'blur',
      }),
    });
    this.signUpForm = this.fb.group({
      email: new FormControl(null, {
        validators: [Validators.required, Validators.email],
        updateOn: 'blur',
      }),
      password: new FormControl(null, {
        validators: [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(30),
          Validators.pattern('[a-zA-Z0-9]*'),
        ],
        updateOn: 'blur',
      }),
      confirmPassword: new FormControl(null, {
        validators: [Validators.required, this.matchValidator],
        updateOn: 'blur',
      }),
      name: new FormControl(null, {
        validators: [Validators.required],
        updateOn: 'blur',
      }),
    });
    this.resetPasswordForm = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
    });
  }
  submitSignInForm(): void {
    this.signInForm.controls.email.markAsTouched();
    if (!this.signInForm.invalid) {
      this.isLoading = true;
      this.signInForm.disable();
      this.auth
        .login(this.signInForm.getRawValue())
        .pipe(
          concatMap(() =>
            location.pathname === '/player/projects' ? this.dataSource.loadProjects() : of(''),
          ),
        )
        .subscribe(
          () => {
            this.closeLoginModel();
            this.message.info('登入成功');
          },
          () => {
            this.signInForm.enable();
            this.isLoading = false;
          },
        );
    }
  }
  submitSignUpForm(): void {
    if (!this.signUpForm.invalid && this.agreeTerms) {
      const form = this.signUpForm.controls;
      const body = {
        name: form.name.value,
        email: form.email.value,
        password: form.password.value,
        serialNumber: this.card,
      };
      this.isLoading = true;
      this.signUpForm.disable();
      this.http.post('/signup', body).subscribe(
        () => {
          this.closeLoginModel();
          this.message.info('資料傳送成功，請在24小時內去信箱收取帳號認證信，完成註冊流程。');
          this.router.navigateByUrl('/');
        },
        () => {
          this.signUpForm.enable();
          this.isLoading = false;
        },
      );
    }
  }
  submitResetPasswordForm(): void {
    if (!this.resetPasswordForm.invalid) {
      this.isLoading = true;
      this.resetPasswordForm.disable();
      this.http.post('/user/password', this.resetPasswordForm.getRawValue()).subscribe(
        () => {
          this.resetModal();
          this.resetPasswordModal.close();
          this.message.info('資料傳送成功，請在24小時內去信箱收取密碼重設認證信。');
        },
        () => (this.isLoading = false),
      );
    }
  }
  showModal(content: TemplateRef<{}>) {
    this.resetPasswordModal = this.modal.create({
      nzTitle: null,
      nzContent: content,
      nzFooter: null,
      nzWidth: 350,
      nzBodyStyle: { 'margin-top': '20vh' },
      nzOnCancel: () => this.resetModal(),
    });
  }
  resetModal() {
    this.isLoading = false;
    this.resetPasswordForm.enable();
    this.resetPasswordForm.reset();
  }
  onLoginChange(event: any) {
    this.http
      .post('/oauth/login', event)
      .pipe(tap((data: ITokenBody) => this.auth.setSession(data)))
      .subscribe(
        () =>
          this.ngZone.run(() => {
            this.closeLoginModel();
            this.message.info('登入成功');
          }),
        () => {
          switch (event.kind) {
            case 'facebook':
              this.facebook.disconnect();
              break;
            case 'google':
              this.google.disconnect();
              break;
          }
        },
      );
  }
  onCardChange(event: string) {
    this.card = event;
  }
  closeLoginModel() {
    if (this.isModal) {
      this.closeModel.emit();
    } else {
      this.router.navigateByUrl(this.returnPath || '/');
    }
  }
}
