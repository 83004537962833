import { Pipe, PipeTransform } from '@angular/core';

const levels = {
  SE: 'Level 1',
  SC: 'Level 2',
  SA: 'Level 3',
  SP: 'Level 4',
  SM: 'Level 5',
};

@Pipe({ name: 'steam' })
export class SteamPipe implements PipeTransform {
  transform(data: string): string {
    const arr = data.split('T');
    const isSteamLevel = levels.hasOwnProperty(arr[0].slice(0, 2));
    const level = isSteamLevel ? levels[arr[0].slice(0, 2)] : '';
    const program = arr[0];
    const topic = isSteamLevel ? `Topic ${arr[1]}` : '';
    return `${level} ${program} ${topic}`.trim();
  }
}
