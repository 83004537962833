<div class="bg-fill bg-light fx-col fx-center">
  <div class="form-wrapper">
    <h4 class="text-center">重新認證信箱</h4>
    <hr class="form-divider" />
    <p class="text-start">
      請輸入您的Email帳號，我們會寄送認證連結至您的Email信箱，請於24小時內收信，並點擊連結確認信箱有效。
    </p>
    <form nz-form [formGroup]="confirmForm">
      <nz-form-item>
        <nz-form-control
          [nzErrorTip]="form.email.hasError('required') ? '請輸入帳號' : '帳號格式錯誤'"
        >
          <nz-input-group nzSize="large" [nzPrefix]="MailIcon">
            <input type="text" nz-input placeholder="信箱" formControlName="email" />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <button
          class="form-btn"
          nz-button
          nzType="primary"
          [nzLoading]="isLoading"
          (click)="submitForm()"
        >
          取得連結
        </button>
      </nz-form-item>
    </form>
  </div>
</div>
<ng-template #MailIcon><i nz-icon nzType="mail"></i></ng-template>
