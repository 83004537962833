import { catchError, takeUntil } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { HttpService } from 'projects/tools/src/lib/services';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Router } from '@angular/router';
import { throwError, Subject } from 'rxjs';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.less'],
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  destroy$ = new Subject();
  isLoading = false;
  isPasswordVisible = false;
  resetPasswordForm: FormGroup;
  constructor(
    private http: HttpService,
    private fb: FormBuilder,
    private message: NzMessageService,
    private router: Router,
  ) {}
  matchValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { required: true };
    }
    return control.value !== this.form.password.value ? { match: true, error: true } : {};
  };
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  ngOnInit() {
    const { token } = this.router.parseUrl(this.router.url).queryParams;
    if (!token) {
      this.router.navigateByUrl('/');
    }
    this.resetPasswordForm = this.fb.group({
      password: new FormControl(null, {
        validators: [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(30),
          Validators.pattern('[a-zA-Z0-9]*'),
        ],
        updateOn: 'blur',
      }),
      confirmPassword: new FormControl(null, {
        validators: [Validators.required, this.matchValidator],
        updateOn: 'blur',
      }),
    });
  }
  get form() {
    return this.resetPasswordForm.controls;
  }
  submitForm(): void {
    if (!this.resetPasswordForm.invalid) {
      const { token } = this.router.parseUrl(this.router.url).queryParams;
      const body = { password: this.form.password.value, token };
      this.isLoading = true;
      this.http
        .post('/user/password', body)
        .pipe(
          catchError((err) => {
            this.isLoading = false;
            this.router.navigateByUrl('/login');
            return throwError(err);
          }),
          takeUntil(this.destroy$),
        )
        .subscribe(() => {
          this.isLoading = false;
          this.message.info('重設密碼成功');
          this.router.navigateByUrl('/login');
        });
    }
  }
}
