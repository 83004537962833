import { Component, Input, OnInit } from '@angular/core';
import { LangService } from 'projects/tools/src/lib/services';

@Component({
  selector: 'app-lang-select',
  template: `
    <button
      nz-button
      nzType="link"
      [style.color]="currentLanguage() === 'zh-tw' ? '#23b6bc' : color"
      (click)="useLanguage('zh-tw')"
    >
      繁中
    </button>
    <span [style.color]="color">|</span>
    <button
      nz-button
      nzType="link"
      [style.color]="currentLanguage() === 'en' ? '#23b6bc' : color"
      (click)="useLanguage('en')"
    >
      EN
    </button>
  `,
  styles: [
    `
      .ant-btn {
        padding: 0 5px;
      }
    `,
  ],
})
export class LangSelectComponent implements OnInit {
  @Input() color: string;
  constructor(private langService: LangService) {}

  ngOnInit(): void {}
  currentLanguage() {
    return this.langService.translate.currentLang;
  }
  useLanguage(lang: string) {
    this.langService.setLang(lang);
  }
}
