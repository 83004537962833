import { catchError, takeUntil } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpService } from 'projects/tools/src/lib/services';
import { NzMessageService } from 'ng-zorro-antd';
import { Router } from '@angular/router';
import { Subject, throwError } from 'rxjs';

@Component({
  selector: 'app-unlock-card',
  templateUrl: './unlock-card.component.html',
  styleUrls: ['./unlock-card.component.less'],
})
export class UnlockCardComponent implements OnInit, OnDestroy {
  card = '';
  destroy$ = new Subject();
  isLoading = false;
  constructor(
    private message: NzMessageService,
    private router: Router,
    private http: HttpService,
  ) {}
  get validator(): boolean {
    return this.card.replace(/[^A-Z0-9]*/g, '').length === 16;
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  ngOnInit() {}
  onCardChange(event: string) {
    this.card = event;
  }
  submitForm(): void {
    if (!this.validator) {
      this.isLoading = true;
      this.http
        .post(`/user/cards`, { serialNumber: this.card })
        .pipe(
          catchError((err) => {
            this.isLoading = false;
            return throwError(err);
          }),
          takeUntil(this.destroy$),
        )
        .subscribe(() => {
          this.isLoading = false;
          this.message.info('解鎖成功');
          this.router.navigateByUrl('/');
        });
    }
  }
}
