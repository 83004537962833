import { Pipe, PipeTransform } from '@angular/core';

export const langMap = {
  'zh-tw': '繁中',
  'zh-cn': '简中',
  en: 'English',
};

@Pipe({ name: 'lang' })
export class LangPipe implements PipeTransform {
  transform(data: string): string {
    return langMap[data];
  }
}
