import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-not-found',
  template: `
    <div class="not-found">
      <h2>Not Found</h2>
    </div>
  `,
  styles: [
    `
      .not-found {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: calc(100vh - 64px);
      }
    `,
  ],
})
export class NotFoundComponent implements OnInit {
  ngOnInit() {}
}
