<main [ngClass]="isModal ? 'bg-white' : 'bg-light'">
  <div class="container fx-col fx-center" [style.min-height]="isModal ? '' : 'calc(100vh - 64px)'">
    <div class="form-wrapper fx-col fx-center">
      <div class="form-brand fx-row fx-center">
        <picture>
          <img class="img-fluid" src="assets/images/logo.png" alt="" />
        </picture>
      </div>
      <div class="space-item">
        <ng-container *ngIf="isSignIn; else SignUpForm">
          <form nz-form [formGroup]="signInForm">
            <ng-container
              *ngTemplateOutlet="MailForm; context: { form: signInForm }"
            ></ng-container>
            <ng-container
              *ngTemplateOutlet="PasswordForm; context: { form: signInForm }"
            ></ng-container>
          </form>
          <div class="fx-col">
            <div class="space-item">
              <button
                class="form-btn"
                nz-button
                nzType="primary"
                [nzLoading]="isLoading"
                (click)="submitSignInForm()"
              >
                {{ 'Login.sign_in' | translate }}
              </button>
            </div>
            <a class="no-padding" nz-button nzType="link" (click)="showModal(ForgetPasswordModal)">
              {{ 'Login.forget_password' | translate }}
            </a>
            <a class="no-padding" nz-button nzType="link" (click)="isSignIn = false">
              {{ 'Login.to_sign_up' | translate }}
            </a>
          </div>
        </ng-container>
        <ng-template #SignUpForm>
          <form nz-form [formGroup]="signUpForm">
            <ng-container
              *ngTemplateOutlet="MailForm; context: { form: signUpForm }"
            ></ng-container>
            <ng-container
              *ngTemplateOutlet="PasswordForm; context: { form: signUpForm }"
            ></ng-container>
            <nz-form-item>
              <nz-form-control
                [nzErrorTip]="
                  signUpForm.controls.confirmPassword.hasError('match')
                    ? ('Error.password_match' | translate)
                    : ('Error.required' | translate: value('Login.password' | translate))
                "
              >
                <nz-input-group [nzPrefix]="LockIcon">
                  <input
                    nz-input
                    type="password"
                    formControlName="confirmPassword"
                    [placeholder]="'Login.confirm_password' | translate"
                  />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-control
                [nzErrorTip]="'Error.required' | translate: value('Login.username' | translate)"
              >
                <nz-input-group [nzPrefix]="UserIcon">
                  <input
                    nz-input
                    formControlName="name"
                    [placeholder]="'Login.username' | translate"
                  />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-control>
                <nz-input-group nzCompact [nzPrefix]="CardIcon">
                  <app-card-input
                    class="fx-row fx-center"
                    [isLoading]="isLoading"
                    (inputChange)="onCardChange($event)"
                  ></app-card-input>
                </nz-input-group>
                <span>{{ 'Login.card' | translate }}</span>
              </nz-form-control>
            </nz-form-item>
          </form>
          <div class="fx-col">
            <div class="space-item text-start">
              <label nz-checkbox [(ngModel)]="agreeTerms" style="margin-right: 10px"></label>
              <span>
                {{ 'Form.agree' | translate }}
                <a
                  class="text-primary"
                  [href]="baseUrl + '/assets/static/privacy.html'"
                  target="_blank"
                >
                  {{ 'Shared.navbar.privacy' | translate }}
                </a>
                &
                <a
                  class="text-primary"
                  [href]="baseUrl + '/assets/static/site.html'"
                  target="_blank"
                >
                  {{ 'Shared.navbar.use_terms' | translate }}
                </a>
              </span>
            </div>
            <div class="space-item">
              <button
                class="form-btn"
                nz-button
                nzType="primary"
                [nzLoading]="isLoading"
                [disabled]="!agreeTerms"
                (click)="submitSignUpForm()"
              >
                {{ 'Login.sign_up' | translate }}
              </button>
            </div>
            <div class="space-item">
              <a
                class="d-block no-padding text-center"
                nz-button
                nzType="link"
                (click)="isSignIn = true"
              >
                {{ 'Login.to_sign_in' | translate }}
              </a>
            </div>
          </div>
        </ng-template>
      </div>
      <hr class="form-divider" />
      <app-google
        class="space-item"
        (loginChange)="onLoginChange($event)"
        [signIn]="isSignIn"
      ></app-google>
      <app-facebook
        class="space-item"
        (loginChange)="onLoginChange($event)"
        [signIn]="isSignIn"
      ></app-facebook>
    </div>
  </div>
</main>
<ng-template #MailForm let-form="form">
  <div [formGroup]="form">
    <nz-form-item>
      <nz-form-control
        [nzErrorTip]="
          form.controls.email.hasError('required')
            ? ('Error.required' | translate: value('Login.email' | translate))
            : ('Error.email_invalid' | translate)
        "
      >
        <nz-input-group [nzPrefix]="MailIcon">
          <input
            [formControl]="form.controls.email"
            autocomplete
            nz-input
            [placeholder]="'Login.email' | translate"
            type="email"
          />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
  </div>
</ng-template>
<ng-template #PasswordForm let-form="form">
  <div [formGroup]="form">
    <nz-form-item>
      <nz-form-control
        [nzErrorTip]="
          form.controls.password.hasError('required')
            ? ('Error.required' | translate: value('Login.password' | translate))
            : form.controls.password.hasError('pattern')
            ? ('Error.password_alphanum' | translate)
            : form.controls.password.hasError('maxlength')
            ? ('Error.password_max_length' | translate)
            : ('Error.password_min_length' | translate)
        "
      >
        <nz-input-group [nzSuffix]="EyeIcon" [nzPrefix]="LockIcon">
          <input
            [placeholder]="'Login.password' | translate"
            [type]="isPasswordVisible ? 'text' : 'password'"
            autocomplete
            formControlName="password"
            nz-input
          />
        </nz-input-group>
        <ng-template #EyeIcon>
          <i
            nz-icon
            [nzType]="isPasswordVisible ? 'eye' : 'eye-invisible'"
            (click)="isPasswordVisible = !isPasswordVisible"
          ></i>
        </ng-template>
      </nz-form-control>
    </nz-form-item>
  </div>
</ng-template>
<ng-template #ForgetPasswordModal>
  <div class="fx-col">
    <h4 class="text-center" [translate]="'Login.reset_password'"></h4>
    <p class="text-start bg-light" [translate]="'Login.reset_password_paragraph'"></p>
    <form nz-form [formGroup]="resetPasswordForm" (ngSubmit)="submitResetPasswordForm()">
      <ng-container *ngTemplateOutlet="MailForm; context: { form: resetPasswordForm }">
      </ng-container>
      <button class="form-btn" nz-button nzBlock nzType="primary" [nzLoading]="isLoading">
        {{ 'Form.submit' | translate }}
      </button>
    </form>
  </div>
</ng-template>
<ng-template #CardIcon><i nz-icon nzType="credit-card"></i></ng-template>
<ng-template #MailIcon><i nz-icon nzType="mail"></i></ng-template>
<ng-template #LockIcon><i nz-icon nzType="lock"></i></ng-template>
<ng-template #UserIcon><i nz-icon nzType="user"></i></ng-template>
