import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { HttpService } from '../services/http.service';
import { Injectable } from '@angular/core';
import { tap, delay, catchError } from 'rxjs/operators';

export interface IUser {
  accounts?: Array<string>;
  avatar: string;
  birthday?: string;
  email: string;
  gender?: string;
  identity?: string;
  isManager?: boolean;
  name: string;
  phoneAreaCode?: string;
  phoneNumber?: string;
  school?: string;
}

@Injectable({ providedIn: 'root' })
export class UserDataSource implements DataSource<IUser> {
  private dataSubject = new BehaviorSubject<IUser>(null);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable().pipe(delay(0));
  constructor(private http: HttpService) {}

  connect(): Observable<any> {
    return this.dataSubject.asObservable();
  }
  disconnect(): void {
    this.dataSubject.complete();
    this.loadingSubject.complete();
  }
  loadUser(): Observable<IUser> {
    this.loadingSubject.next(true);
    return this.http.get(`/user/profile`).pipe(
      tap(() => this.loadingSubject.next(false)),
      tap((data) => this.dataSubject.next(data)),
      catchError((err) => {
        this.loadingSubject.next(false);
        return throwError(err);
      }),
    );
  }
  updateUser(body = {}): Observable<IUser> {
    const { id } = JSON.parse(localStorage.getItem('access_info'));
    this.loadingSubject.next(true);
    return this.http.patch(`/users/${id}`, body).pipe(
      tap(() => this.loadingSubject.next(false)),
      tap((data) => this.dataSubject.next(data)),
      catchError((err) => {
        this.loadingSubject.next(false);
        return throwError(err);
      }),
    );
  }
}
