<main>
  <section class="banner bg fx-col fx-center">
    <h2 class="text-white text-bold text-center">
      <I>Professional Development</I>
    </h2>
  </section>
  <section id="why-teacher-training">
    <div class="container">
      <h2 class="text-center">{{ 'Training.why.heading' | translate }}</h2>
      <div class="fx-row fx-center justify-between reason-wrapper">
        <div
          *ngFor="let reason of 'Training.why.reason' | translate; let i = index"
          class="fx-col justify-end reason"
        >
          <picture>
            <img class="img-fluid" [src]="hoverImg(i, 'default')" />
            <img class="img-fluid" [src]="hoverImg(i, 'hover')" class="hover" />
          </picture>
          <div class="fx-col fx-center text-start">
            <h4>{{ reason.heading }}</h4>
            <p class="h6 text-center no-margin">{{ reason.paragraph }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="bg-light" id="training-course">
    <div class="container fx-row fx-wrap justify-between">
      <div class="fx-col justify-center topic">
        <h4 [innerHTML]="'Training.course.heading' | translate"></h4>
        <hr class="bg-primary" style="margin: 20px 0" />
      </div>
      <section *ngFor="let topic of 'Training.course.topic' | translate" class="topic">
        <picture>
          <img class="img-fluid" [src]="topic.image" />
        </picture>
        <div [style.background-color]="topic.bg_color" class="cover">
          <h4 class="text-white">{{ topic.heading }}</h4>
        </div>
      </section>
    </div>
  </section>
  <section class="bg" id="training-process">
    <div class="container">
      <h2 class="text-white text-center">
        {{ 'Training.process.heading' | translate }}
      </h2>
      <hr class="bg-white" />
      <div class="fx-row fx-center fx-wrap justify-between">
        <div
          *ngFor="let step of 'Training.process.step' | translate; let i = index"
          class="fx-col fx-center step"
        >
          <picture><img class="img-fluid" [src]="stepImg(i)" /></picture>
          <h5 class="text-white text-center">{{ step }}</h5>
        </div>
      </div>
    </div>
  </section>
  <section id="certification">
    <div class="container">
      <h2 class="text-center">
        {{ 'Training.certification.heading' | translate }}
      </h2>
      <hr />
      <div
        class="align-center justify-between"
        [ngClass]="(isMobile$ | async) ? 'fx-col' : 'fx-row'"
      >
        <div
          *ngFor="let level of 'Training.certification.level' | translate; let i = index"
          [style.background-image]="levelBgImg(i)"
          class="bg level-card space-item"
        >
          <div class="fx-col fx-center">
            <h3
              [innerHtml]="level.heading"
              class="text-white text-center"
              style="margin-bottom: 40px"
            ></h3>
            <div>
              <p
                [innerHTML]="level.paragraph_1"
                class="h5 text-white text-center"
                style="min-height: 111px"
              ></p>
              <hr class="bg-white" />
              <p class="h5 text-white text-center">{{ level.paragraph_2 }}</p>
              <hr class="bg-white" />
              <p
                [innerHTML]="level.paragraph_3"
                class="h6 text-white text-center"
                style="min-height: 84px"
              ></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="banner bg fx-col fx-center" id="cooperation">
    <div class="container">
      <div class="fx-col justify-center">
        <h2 class="text-white" [ngClass]="(isMobile$ | async) ? 'text-center' : ''">
          {{ 'Shared.cooperation.heading' | translate }}
        </h2>
        <p class="h5 text-white">
          {{ 'Shared.cooperation.paragraph' | translate }}
        </p>
        <a
          class="btn btn-outline-primary no-border"
          routerLink="/contact-us"
          [ngClass]="(isMobile$ | async) ? 'align-self-center' : ''"
        >
          {{ 'Shared.btn.contact_us' | translate }}
        </a>
      </div>
    </div>
  </section>
</main>
<app-footer></app-footer>
