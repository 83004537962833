import { Component, OnInit, Output, Input, EventEmitter, NgZone } from '@angular/core';
import { concatMap } from 'rxjs/operators';
import { environment } from 'projects/fliprobot/src/environments/environment';
import { HttpService } from 'projects/tools/src/lib/services';
import { NzMessageService } from 'ng-zorro-antd/message';
import { of } from 'rxjs';

@Component({
  selector: 'app-facebook',
  templateUrl: './facebook.component.html',
  styles: [
    `
      .anticon {
        color: #1877f2;
        font-size: 20px;
        margin: 2px 10px 0;
      }
      .ant-btn {
        font-size: 16px;
        height: 40px;
      }
      .ant-btn:hover,
      .ant-btn:focus {
        border-color: #1877f2;
      }
      .binding {
        background-color: #1877f2;
        color: #fff;
      }
    `,
  ],
})
export class FacebookComponent implements OnInit {
  @Input() isBinding: boolean;
  @Input() signIn: boolean;
  @Output() loginChange: EventEmitter<any> = new EventEmitter<any>();
  inLoginPage: boolean;
  value = (value: string) => ({ value, kind: 'Facebook' });
  constructor(
    private http: HttpService,
    private message: NzMessageService,
    private ngZone: NgZone,
  ) {}

  ngOnInit(): void {
    this.facebookSDK();
    this.inLoginPage = typeof this.signIn !== 'undefined';
  }
  facebookSDK() {
    (window as any).fbAsyncInit = function () {
      window['FB'].init({
        appId: environment.facebook.appId,
        cookie: true,
        xfbml: true,
        version: 'v5.0',
      });
      window['FB'].AppEvents.logPageView();
    };
    (function (d, s, id) {
      let js;
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  }
  async login() {
    try {
      const authResponse = await new Promise((resolve, reject) => {
        window['FB'].login(
          async (response) => {
            response.authResponse && !response.error ? resolve(response.authResponse) : reject();
          },
          {
            scope: 'email',
            return_scopes: true,
          },
        );
      });
      const profile = await new Promise((resolve, reject) => {
        window['FB'].api('/me', 'POST', { fields: 'id,email,name' }, function (response) {
          if (response && !response.error) {
            resolve(response);
          } else {
            reject(response.error);
          }
        });
      });
      this.loginChange.emit({
        avatar: `https://graph.facebook.com/v5.0/${profile['id']}/picture?width=160&height=160`,
        email: profile['email'],
        kind: 'facebook',
        name: profile['name'],
        token: authResponse['accessToken'],
        uid: profile['id'],
      });
    } catch (error) {}
  }
  disconnect() {
    try {
      window['FB'].api('/me/permissions', 'delete');
    } catch (error) {}
  }
  unbind() {
    this.http
      .delete('/user/account/facebook')
      .pipe(
        concatMap(() =>
          of(
            new Promise((resolve, reject) => {
              window['FB'].api('/me/permissions', 'delete', (response) => {
                response ? resolve(response) : reject(response.error);
              });
            }),
          ),
        ),
      )
      .subscribe(() =>
        this.ngZone.run(() => {
          this.isBinding = false;
          this.message.info(`取消連結 Facebook 帳號成功。`);
        }),
      );
  }
}
