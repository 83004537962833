import { Pipe, PipeTransform } from '@angular/core';

export const CountryMap = {
  // AU: {
  //   label: 'Australia',
  //   lang: 'en',
  //   icon: 'assets/icon/au.png',
  //   value: 'au',
  // },

  // NZ: {
  //   label: 'New Zealand',
  //   lang: 'en',
  //   icon: 'assets/icon/nz.png',
  //   value: 'nz',
  // },
  // SG: {
  //   label: 'Singapore',
  //   lang: 'en',
  //   icon: 'assets/icon/sg.png',
  //   value: 'sg',
  // },
  TW: {
    label: '台灣',
    lang: 'zh-tw',
    icon: 'assets/icon/tw.png',
    value: 'tw',
  },
};

@Pipe({ name: 'country' })
export class CountryPipe implements PipeTransform {
  transform(data: string): string {
    return CountryMap[data].label;
  }
}
