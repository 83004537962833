import { SharedModule } from './../shared/shared.module';
import { AntdModule } from 'projects/tools/src/lib/antd.module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LayoutModule } from '../layout/layout.module';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ToolsModule } from 'projects/tools/src/lib/tools.module';
import { TranslateModule } from '@ngx-translate/core';

import { AboutUsComponent } from './about-us/about-us.component';
import { ConfirmEmailComponent } from './confirm-email/confirm-email.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { FlipToolsComponent } from './flip-tools/flip-tools.component';
import { HomeComponent } from './home/home.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { TrainingComponent } from './training/training.component';
import { UnlockCardComponent } from './unlock-card/unlock-card.component';
import { DownloadBtnComponent } from './flip-tools/download-btn/download-btn.component';

@NgModule({
  declarations: [
    AboutUsComponent,
    ConfirmEmailComponent,
    ContactUsComponent,
    FlipToolsComponent,
    HomeComponent,
    ResetPasswordComponent,
    TrainingComponent,
    UnlockCardComponent,
    DownloadBtnComponent,
  ],
  imports: [
    AntdModule,
    CommonModule,
    FormsModule,
    LayoutModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    ToolsModule,
    TranslateModule,
  ],
})
export class PageModule {}
