import { Pipe, PipeTransform } from '@angular/core';

export const actionMap = {
  use: '線上版',
  download: '單機版',
};

@Pipe({ name: 'action' })
export class ActionPipe implements PipeTransform {
  transform(data: string): string {
    return actionMap[data];
  }
}
