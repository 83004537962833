import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { AuthService, HttpService } from 'projects/tools/src/lib/services';
import { Observable, Subject } from 'rxjs';
import { map, take, takeUntil, tap } from 'rxjs/operators';

export interface Setting {
  translateName: string;
  href?: string;
  checkDownloadAccess?: boolean;
  hasAccessToken?: boolean;
  downloadUrl?: string;
  isDisabled?: boolean;
}

/**
 * download button or direct link button
 */
@Component({
  selector: 'app-download-btn',
  template: `
    <ng-container *ngIf="isLogin && setting.checkDownloadAccess; else linkBtn">
      <button
        class="btn btn-outline-primary"
        (click)="open(downloadUrl)"
        [disabled]="(downloadAccess$ | async) === false"
      >
        {{ setting.translateName | translate }}
      </button>
    </ng-container>
    <ng-template #linkBtn>
      <button
        class="btn btn-outline-primary"
        (click)="
          open(setting.hasAccessToken && isLogin ? getLinkWithToken(setting.href) : setting.href)
        "
        [disabled]="setting.isDisabled"
      >
        {{ setting.translateName | translate }}
      </button>
    </ng-template>
  `,
  styleUrls: ['./download-btn.component.less'],
})
export class DownloadBtnComponent implements OnInit, OnDestroy {
  @Input() setting: Setting;
  @Input() type: string;
  isLogin: boolean;
  isLogin$: Observable<boolean>;
  downloadAccess$: Observable<boolean>;
  destroy$ = new Subject();
  downloadUrl: string;
  constructor(private auth: AuthService, private http: HttpService) {}

  ngOnInit(): void {
    this.auth
      .isLogin()
      .pipe(
        tap((isLogin) => (this.isLogin = isLogin)),
        takeUntil(this.destroy$),
      )
      .subscribe();
    this.downloadAccess$ = this.http
      .post('/software', {
        country: localStorage.getItem('country'),
        type: this.type,
        url: this.setting.downloadUrl || '',
      })
      .pipe(
        map((data) => {
          if (data.signedUrl) {
            this.downloadUrl = data.signedUrl;
          }
          return !!data.signedUrl;
        }),
        take(1),
      );
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  getLinkWithToken(link: string) {
    return `${link}?accessToken=${localStorage.getItem('access_token')}`;
  }
  open = (href: string) => window.open(href, '_blank');
}
