import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, tap, delay, concatMap } from 'rxjs/operators';
import { DataSource } from '@angular/cdk/collections';
import { HttpParams } from '@angular/common/http';
import { HttpService } from '../services/http.service';
import { Injectable } from '@angular/core';

export interface IProject {
  _id: string;
  author: { avatar: string | null; role: string; name: string };
  bookmark: boolean;
  categories: Array<string>;
  classHour: number;
  content?: string;
  cover: string;
  createdAt: Date | string;
  files: Array<{ name: string; size: number; type: string; xml: string }>;
  lang: string;
  like: boolean;
  likeCount: number;
  product: Array<string>;
  published: boolean;
  schoolAge: number;
  sharer: string;
  source: string;
  story: string;
  tags: Array<string>;
  title: string;
  updatedAt: Date | string;
  videoUrl: string | null;
  viewCount: number;
}
export interface IProjectBody {
  page: number;
  pageSize: number;
  projects: Array<IProject>;
  totalCount: number;
}

@Injectable({
  providedIn: 'root',
})
export class ProjectDataSource implements DataSource<IProjectBody> {
  private initData: IProjectBody = {
    page: 0,
    pageSize: 0,
    projects: [],
    totalCount: 0,
  };
  private dataSubject = new BehaviorSubject<IProjectBody>(null);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  // setTimeout to prevent ExpressionChangedAfterItHasBeenCheckedError
  public loading$ = this.loadingSubject.asObservable().pipe(delay(0));
  constructor(private http: HttpService) {}

  connect(): Observable<any> {
    return this.dataSubject.asObservable();
  }
  disconnect(): void {
    this.dataSubject.complete();
    this.loadingSubject.complete();
  }
  loadProjects(params?: HttpParams): Observable<IProjectBody> {
    this.loadingSubject.next(true);
    return this.http.get('/projects', params).pipe(
      tap(() => this.loadingSubject.next(false)),
      tap((data) => this.dataSubject.next(data)),
      catchError(() => {
        this.loadingSubject.next(false);
        return of(this.initData);
      }),
    );
  }
  updateProject(id: string, body = {}): Observable<any> {
    return this.http.patch(`/projects/${id}`, body);
  }
  deleteProject(id: string): Observable<any> {
    return this.http.delete(`/projects/${id}`);
  }
}
