<div class="bg-fill bg-light fx-col fx-center">
  <div class="form-wrapper">
    <h4 class="text-center">重設密碼</h4>
    <hr class="form-divider" />
    <p class="text-start">請輸入您的新密碼。</p>
    <form nz-form [formGroup]="resetPasswordForm">
      <nz-form-item>
        <nz-form-control
          [nzErrorTip]="
            form.password.hasError('required')
              ? '請輸入密碼'
              : form.password.hasError('pattern')
              ? '限大小寫英文及數字'
              : '至少8位'
          "
        >
          <nz-input-group [nzSuffix]="EyeIcon" [nzSize]="'large'" [nzPrefix]="LockIcon">
            <ng-template #EyeIcon>
              <i
                nz-icon
                [nzType]="isPasswordVisible ? 'eye' : 'eye-invisible'"
                (click)="isPasswordVisible = !isPasswordVisible"
              ></i>
            </ng-template>
            <input
              nz-input
              [type]="isPasswordVisible ? 'text' : 'password'"
              placeholder="新密碼"
              formControlName="password"
            />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control
          [nzErrorTip]="form.confirmPassword.hasError('match') ? '密碼不符' : '請再輸入一次密碼'"
        >
          <nz-input-group [nzSize]="'large'" [nzPrefix]="LockIcon">
            <input
              nz-input
              type="password"
              placeholder="確認密碼"
              formControlName="confirmPassword"
            />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <button
          class="form-btn"
          nz-button
          nzType="primary"
          [nzLoading]="isLoading"
          (click)="submitForm()"
        >
          重設密碼
        </button>
      </nz-form-item>
    </form>
  </div>
</div>
<ng-template #LockIcon><i nz-icon nzType="lock"></i></ng-template>
