import { DEVICE } from 'projects/tools/src/lib/services/rwd.service';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { RwdService } from 'projects/tools/src/lib/services';
import { shareReplay } from 'rxjs/operators';

@Component({
  selector: 'app-training',
  templateUrl: './training.component.html',
  styleUrls: ['./training.component.less'],
})
export class TrainingComponent implements OnInit {
  isMobile$: Observable<boolean>;
  path = 'assets/images/teacher/training';
  constructor(private rwd: RwdService) {}

  ngOnInit(): void {
    this.isMobile$ = this.rwd
      .breakPointQuery([DEVICE.HANDSET, DEVICE.TABLET_PORTRAIT])
      .pipe(shareReplay(1));
  }
  hoverImg(index: number, state: string): string {
    return `${this.path}/why/${state}/${index + 1}.png`;
  }
  levelBgImg(index: number): string {
    return `url(/${this.path}/level/${index + 1}.png)`;
  }
  stepImg(index: number): string {
    return `${this.path}/process/${index + 1}.png`;
  }
}
