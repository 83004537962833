import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { RwdService } from 'projects/tools/src/lib/services';
import { shareReplay } from 'rxjs/operators';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.less'],
})
export class ContactUsComponent implements OnInit {
  isMobile$: Observable<boolean>;
  constructor(private rwd: RwdService) {}

  ngOnInit(): void {
    this.isMobile$ = this.rwd.isMobile.pipe(shareReplay(1));
  }
}
