import { Base64 } from 'js-base64';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpService } from './http.service';
import { Injectable } from '@angular/core';
import { isBefore, minTime } from 'date-fns';
import { tap, shareReplay } from 'rxjs/operators';

export interface ITokenBody {
  accessToken: string;
  expiresIn: number;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private path = '/login';
  private isLogin$ = new BehaviorSubject<boolean>(this.isTokenValid());
  constructor(private http: HttpService) {}
  get loggedIn() {
    return this.isTokenValid();
  }
  private isTokenValid(): boolean {
    const token = localStorage.getItem('access_token') || false;
    const exp = parseInt(localStorage.getItem('access_exp'), 10) || minTime;
    // token does not expired.
    const isValid = isBefore(Math.floor(Date.now() / 1000), exp);
    if (!isValid) {
      this.clearAuthData();
    }
    return token && isValid;
  }
  private clearAuthData(): void {
    localStorage.removeItem('access_exp');
    localStorage.removeItem('access_info');
    localStorage.removeItem('access_token');
    // old storage key
    localStorage.removeItem('access_token');
    localStorage.removeItem('login_expiration');
    localStorage.removeItem('user_id');
    localStorage.removeItem('user_account');
    localStorage.removeItem('user_info');
  }
  set setPath(path: string) {
    this.path = path;
  }
  setSession(data: ITokenBody): void {
    localStorage.setItem('access_token', data.accessToken);
    localStorage.setItem('access_exp', `${Math.floor(Date.now() / 1000) + data.expiresIn}`);
    // atob() can decode base64 except for unicode.
    // decodeURIComponent() fix this error but also fail when encountering some chinese word.
    const payload = JSON.parse(Base64.decode(data.accessToken.split('.')[1]));
    const accessInfo = { iat: payload.iat, id: payload.sub, iss: payload.iss };
    localStorage.setItem('access_info', JSON.stringify(accessInfo));
    this.isLogin$.next(true);
  }
  isLogin(): Observable<boolean> {
    return this.isLogin$.asObservable().pipe(shareReplay(1));
  }
  login(body: { email: string; password: string }): Observable<ITokenBody> {
    return this.http.post(this.path, body).pipe(tap((data) => this.setSession(data)));
  }
  logout(): void {
    this.clearAuthData();
    this.isLogin$.next(false);
  }
}
