export const environment = {
  production: true,
  baseUrl: 'https://www.fliprobot.com.tw',
  backend: 'https://www.fliprobot.com.tw/api',
  version: 'v1',
  google: {
    appId: '51403227179-sbt3asqhdgrgidffqi749ng9nrdr1d5n.apps.googleusercontent.com',
    gaId: 'UA-61575223-9',
  },
  facebook: {
    appId: '2423999084577258',
  },
};
