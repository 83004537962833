import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LayoutModule } from './layout/layout.module';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { NgModule } from '@angular/core';
import { PageModule } from './page/page.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';

import { AppComponent } from './app.component';
import { icons } from 'projects/tools/src/lib/icons/icon.constant';
import { JwtInterceptor, ErrorInterceptor } from 'projects/tools/src/lib/interceptors';
import { NZ_ICONS } from 'ng-zorro-antd/icon';

// angular i18n config
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
registerLocaleData(en);

// ng-zorro global configuration
import { NzConfig, NZ_CONFIG } from 'ng-zorro-antd/core/config';
const ngZorroConfig: NzConfig = {
  message: { nzTop: 90 },
  notification: { nzTop: 90 },
};

// ng-zorro i18n config
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';

export function HttpLoaderFactory(http: HttpClient) {
  return new MultiTranslateHttpLoader(http, [
    {
      prefix: '/assets/i18n/',
      suffix: '.json',
    },
    {
      prefix: '/assets/i18n/shared/',
      suffix: '.json',
    },
  ]);
}
@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    PageModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    LayoutModule,
  ],
  providers: [
    { provide: NZ_I18N, useValue: en_US },
    { provide: NZ_ICONS, useValue: icons },
    { provide: NZ_CONFIG, useValue: ngZorroConfig },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
