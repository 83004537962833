import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Post } from '../../data/post';

@Component({
  selector: 'app-redirect-to-blog',
  template: ``,
  styles: [],
})
export class RedirectToBlogComponent implements OnInit {
  posts: string[] = Post;
  constructor(private router: Router) {}

  ngOnInit() {
    const isPost = this.posts.find((post) => post === window.location.pathname) || false;
    if (isPost) {
      window.location.href = 'https://blog.fliprobot.com' + window.location.pathname;
    } else {
      this.router.navigateByUrl('/404');
    }
  }
}
