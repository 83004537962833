import { Component, OnInit, Output, EventEmitter, Input, NgZone } from '@angular/core';
import { concatMap } from 'rxjs/operators';
import { environment } from 'projects/fliprobot/src/environments/environment';
import { HttpService } from 'projects/tools/src/lib/services';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-google',
  templateUrl: './google.component.html',
  styles: [
    `
      .anticon {
        color: #ea4335;
        font-size: 20px;
        margin: 2px 10px 0;
      }
      .ant-btn {
        font-size: 16px;
        height: 40px;
      }
      .ant-btn:hover,
      .ant-btn:focus {
        border-color: #ea4335;
      }
      .binding {
        background-color: #ea4335;
        color: #fff;
      }
    `,
  ],
})
export class GoogleComponent implements OnInit {
  @Input() isBinding: boolean;
  @Input() signIn: boolean;
  @Output() loginChange: EventEmitter<any> = new EventEmitter<any>();
  inLoginPage: boolean;
  value = (value: string) => ({ value, kind: 'Google' });

  constructor(
    private http: HttpService,
    private message: NzMessageService,
    private ngZone: NgZone,
  ) {}

  ngOnInit(): void {
    this.googleSDK();
    this.inLoginPage = typeof this.signIn !== 'undefined';
  }
  googleSDK() {
    window['googleSDKLoaded'] = () => {
      window['gapi'].load('auth2', () => {
        window['gapi'].auth2.init({
          client_id: environment.google.appId,
          cookiepolicy: 'single_host_origin',
        });
      });
    };
    (function (d, s, id) {
      let js;
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://apis.google.com/js/platform.js?onload=googleSDKLoaded';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'google-jssdk');
  }
  login() {
    const auth2 = window['gapi'].auth2.getAuthInstance();
    auth2
      .signIn({ scope: 'profile email' })
      .then(() => {
        const profile = auth2.currentUser.get().getBasicProfile();
        this.loginChange.emit({
          avatar: profile.getImageUrl(),
          email: profile.getEmail(),
          kind: 'google',
          name: profile.getName(),
          token: auth2.currentUser.get().getAuthResponse().access_token,
          uid: profile.getId(),
        });
      })
      .catch();
  }
  disconnect() {
    const auth2 = window['gapi'].auth2.getAuthInstance();
    auth2.disconnect();
  }
  unbind() {
    const auth2 = window['gapi'].auth2.getAuthInstance();
    this.http
      .delete('/user/account/google')
      .pipe(concatMap(() => auth2.disconnect()))
      .subscribe(() =>
        this.ngZone.run(() => {
          this.isBinding = false;
          this.message.info(`取消連結 Google 帳號成功。`);
        }),
      );
  }
}
