import { Injectable } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Observable } from 'rxjs';
import { ProjectEditingComponent } from 'projects/fliprobot/src/app/project/project-editing/project-editing.component';
import {
  UrlTree,
  CanDeactivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class SaveGuard implements CanDeactivate<ProjectEditingComponent> {
  constructor(private modal: NzModalService) {}
  canDeactivate(
    component: ProjectEditingComponent,
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return component.saveGuard
      ? new Promise((resolve) => {
          this.modal.create({
            nzTitle: null,
            nzContent: '你的專題還沒儲存，確定要離開頁面嗎?',
            nzOnOk: () => resolve(true),
          });
        })
      : true;
  }
}
