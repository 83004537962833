import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    const token = localStorage.getItem('access_token');
    const headers = {
      'Access-Control-Allow-Methods': 'GET, PUT, POST, PATCH, DELETE, OPTIONS',
      'Access-Control-Max-Age': '86400',
    };
    return token
      ? next.handle(
          request.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`,
              ...headers,
            },
          }),
        )
      : next.handle(
          request.clone({
            setHeaders: headers,
          }),
        );
  }
}
