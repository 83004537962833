import { catchError, takeUntil } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { HttpService } from 'projects/tools/src/lib/services';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Router } from '@angular/router';
import { Subject, throwError } from 'rxjs';

@Component({
  selector: 'app-confirm-email',
  templateUrl: './confirm-email.component.html',
  styleUrls: ['./confirm-email.component.less'],
})
export class ConfirmEmailComponent implements OnInit, OnDestroy {
  confirmForm: FormGroup;
  destroy$ = new Subject();
  isLoading = false;
  constructor(
    private fb: FormBuilder,
    private http: HttpService,
    private message: NzMessageService,
    private router: Router,
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  ngOnInit() {
    const { token } = this.router.parseUrl(this.router.url).queryParams;
    if (!token) {
      this.router.navigateByUrl('/');
    }
    this.confirmForm = this.fb.group({
      email: new FormControl(null, {
        validators: [Validators.required, Validators.email],
        updateOn: 'blur',
      }),
    });
    this.http
      .post('/user/email', { token })
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.message.info('信箱確認成功，您可以開始登入FlipRobot網站');
        this.router.navigateByUrl('/login');
      });
  }
  get form() {
    return this.confirmForm.controls;
  }
  submitForm() {
    if (!this.confirmForm.invalid) {
      this.isLoading = true;
      this.http
        .post('/user/email', this.confirmForm.getRawValue())
        .pipe(
          catchError((err) => {
            this.isLoading = false;
            return throwError(err);
          }),
          takeUntil(this.destroy$),
        )
        .subscribe(() => {
          this.isLoading = false;
          this.message.info('認證信寄送成功');
          this.router.navigateByUrl('/login');
        });
    }
  }
}
