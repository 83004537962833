export const Post = [
  '/2017-irex',
  '/vr-learning',
  '/light-perception-rod-bionic',
  '/3dprint-class',
  '/fliprobot-presentation-in-australia',
  '/send-the-robot-to-the-local-activities',
  '/technology-steam-lecturer',
  '/visit-the-platform-president',
  '/malaysian-overseas-chinese-school',
  '/fliprobot-teacher-training',
  '/21ct',
  '/pbl',
  '/kang-chiao-kindergarten',
  '/professor-of-distance-learning',
  '/beijing-tsinghua-university',
  '/steam',
  '/twelf-the-first-robot-steam-course',
  '/201801vrhappylearningplan',
  '/computational-thinking',
  '/5c-for-future-success',
  '/computational-thinking-not-learning-program',
  '/total-solution-of-steam-education',
  '/flip-education-with-fliprobot',
  '/training-program-in-junyi',
  '/design-thinking-school',
  '/5c-abilities-2-0',
  '/design-thinking-integrated-into-robot-course',
  '/are-you-ready-for-the-5c-ability',
  '/learn-robot-and-design-thinking',
  '/who-is-responsible-for-robot-and-human-accidents',
  '/design-thinking-and-computational-thinking-in-education',
  '/the-dancing-words-a-brief-talk-about-dyslexia',
  '/happiness-is-a-compulsory-in-india',
  '/summon-your-inner-student-cpbl',
  '/what-is-ai-iq-test-for-robots-turing-test',
  '/new-examination-system-emphasis-on-thought-process',
  '/transferable-skill-complex-problem-solving',
  '/what-do-teachers-think-about-integrating-robotics-into-education',
  '/phenomenon-based-learning-starts-from-finland',
  '/how-united-states-takes-stem-education-seriously',
  '/make-robots-move',
  '/robotics-learning-for-beginners-04-what-is-ipo',
  '/robotics-learning-for-beginners-01-outline',
  '/the-latest-publication-of-self-learning-with-fliprobot-e300',
  '/robotics-learning-for-beginners-05-sensor-and-robot',
  '/robotics-learning-for-beginners-06-robotics-intelligence',
  '/the-importance-of-professional-training',
  '/from-three-laws-of-robotics-to-laws-of-ai',
  '/robotics-learning-for-beginners-02-introduction-to-robots',
  '/the-importance-of-education',
  '/108-curriculum-outline-and-robotic-education',
  '/disscussion-about-da-vinci-surgery',
  '/fliprobot-spaceu-project-with-nasa',
  '/fliprobot-class-note-07',
  '/fliprobot-class-note-06',
  '/fliprobot-class-note-05',
  '/fliprobot-class-note-04',
  '/fliprobot-class-note-03',
  '/fliprobot-class-note-02',
  '/fliprobot-class-note-01',
  '/fliprobot-class-note-08',
  '/fliprobot-class-note-09',
  '/international-academic-approval',
  '/computational-thinking-education',
  '/smart-city-home-for-the-future',
  '/discussing-gender-on-computational-thinking-performance',
  '/fliprobot-class-note-10',
  '/moral-discussion-on-self-driving-cars',
  '/cooperation-between-fliprobot-and-nasa-space-center-houston',
  '/108-curriculum-outline-and-core-competencies-test',
  '/teaching-aids',
];
