<main>
  <div class="banner bg fx-col fx-center">
    <div class="container fx-col">
      <h2 class="text-primary" [ngClass]="(isMobile$ | async) ? 'text-center' : 'text-start'">
        {{ 'Contact.heading' | translate }}
      </h2>
      <p class="h6">{{ 'Contact.paragraph' | translate }}</p>
      <app-qrcode-wrapper
        imgSrc="/assets/images/page/home/qrcode_line.png"
        text="翻轉機器人官方LINE"
      ></app-qrcode-wrapper>
    </div>
  </div>
</main>
<app-footer></app-footer>
