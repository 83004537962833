import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { map, catchError, tap, switchMap } from 'rxjs/operators';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Observable, of } from 'rxjs';
import { TeachingResourceDataSource } from '../data/teaching-resource.data';

@Injectable({
  providedIn: 'root',
})
export class CardGuard implements CanActivate {
  constructor(
    private dataSource: TeachingResourceDataSource,
    private message: NzMessageService,
    private router: Router,
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.dataSource.connect().pipe(
      switchMap((resources) =>
        resources === null ? this.dataSource.loadResources() : of(resources),
      ),
      map((resources) => resources.length !== 0),
      tap((isNotEmpty) => {
        if (!isNotEmpty) {
          this.router.navigateByUrl('/user/cards');
          this.message.info('請先解鎖課程');
        }
      }),
      catchError(() => of(false)),
    );
  }
  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(route, state);
  }
}
