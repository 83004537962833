<ng-container *ngIf="inLoginPage; else setting">
  <button class="fx-row fx-center" nz-button nzBlock (click)="login()">
    <i nz-icon nzType="google"></i>
    <span
      [innerHTML]="'Form.oauth_login' | translate: value((signIn ? 'Login.sign_in' : 'Login.sign_up') | translate)"
    ></span>
  </button>
</ng-container>
<ng-template #setting>
  <ng-container *ngIf="isBinding; else unbinding">
    <button class="binding fx-row fx-center" nz-button nzBlock (click)="unbind()">
      <i nz-icon nzType="google" [style.color]="'#fff'"></i>
      <span [innerHTML]="'Form.oauth_binding' | translate: value('Form.unbinding' | translate)"></span>
    </button>
  </ng-container>
  <ng-template #unbinding>
    <button class="fx-row fx-center" nz-button nzBlock (click)="login()">
      <i nz-icon nzType="google" [style.color]="'#ea4335'"></i>
      <span [innerHTML]="'Form.oauth_binding' | translate: value('Form.binding' | translate)"></span>
    </button>
  </ng-template>
</ng-template>
