import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, tap, delay } from 'rxjs/operators';
import { DataSource } from '@angular/cdk/collections';
import { HttpParams } from '@angular/common/http';
import { HttpService } from '../services/http.service';
import { ICourse } from './course.data';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TeachingResourceDataSource implements DataSource<Array<ICourse>> {
  private dataSubject = new BehaviorSubject<Array<ICourse>>(null);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable().pipe(delay(0));
  constructor(private http: HttpService) {}

  connect(): Observable<any> {
    return this.dataSubject.asObservable();
  }
  disconnect(): void {
    this.dataSubject.complete();
    this.loadingSubject.complete();
  }
  loadResources(params?: HttpParams): Observable<Array<ICourse>> {
    const country = localStorage.getItem('country');
    this.loadingSubject.next(true);
    return this.http.get(`/teaching-resource?country=${country}`, params).pipe(
      tap(() => this.loadingSubject.next(false)),
      tap((data) => this.dataSubject.next(data)),
      catchError(() => {
        this.loadingSubject.next(false);
        return of([]);
      }),
    );
  }
}
