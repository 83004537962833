import { AuthService, RwdService } from 'projects/tools/src/lib/services';
import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { CountryMap } from 'projects/tools/src/lib/pipes/country.pipe';
import { Observable, of } from 'rxjs';
import { shareReplay, map, concatMap, filter } from 'rxjs/operators';
import { UserDataSource, IUser } from 'projects/tools/src/lib/data/user.data';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.less'],
})
export class NavbarComponent implements OnInit, OnChanges {
  @Input() screenTop: boolean;
  avatar$: Observable<string>;
  country: { icon: string; label: string; lang: string; value: string };
  countryOptions: any;
  isLogin$: Observable<boolean>;
  isLoginPath: boolean;
  isManager$: Observable<boolean>;
  isMenuVisible = false;
  isModalVisible = false;
  isWeb$: Observable<boolean>;
  navbarBgColor: string;
  navbarBrandImg: string;
  navbarFontColor: string;
  reload = false;
  mobileDropdownStyle = {
    'background-color': '#fff',
    'box-shadow': '2px 2px 6px 0 rgba(3, 27, 78, 0.1)',
    width: '100vw',
  };
  navItems = [
    {
      label: 'home',
      link: '/',
      icon: 'home',
    },
    {
      label: 'academy',
      href: 'https://actura.com.tw/casefliprobotacadem',
      icon: 'shop',
    },
    {
      label: 'course.main',
      link: '/courses',
      icon: 'read',
    },
    {
      label: 'training.main',
      link: '/teacher/training',
      icon: 'play-square',
    },
    {
      label: 'player.knowledge_base',
      href: 'https://fliprobot.gitbook.io/knowledge-base',
      icon: 'bulb',
    },
    {
      label: 'player.project',
      link: '/player/projects',
      icon: 'rocket',
    },
    {
      label: 'tools',
      link: '/tools',
      icon: 'tool',
    },
    // {
    //   label: 'shop',
    //   href: 'https://shop.fliprobot.com.tw/',
    //   icon: 'shop',
    // },
  ];
  userMenuItems = [
    {
      label: 'user.info',
      link: '/user',
      icon: 'user',
    },
    {
      label: 'user.setting',
      link: '/user/setting',
      icon: 'setting',
    },
    {
      label: 'user.card',
      link: '/user/cards',
      icon: 'credit-card',
    },
    {
      label: 'user.project',
      link: '/user/projects',
      icon: 'rocket',
    },
    // {
    //   label: 'user.notification',
    //   link: '/user/notification',
    //   icon: 'notification',
    // },
  ];
  constructor(
    private auth: AuthService,
    private dataSource: UserDataSource,
    private rwd: RwdService,
  ) {}

  get pathname(): string {
    return location.pathname;
  }
  ngOnInit(): void {
    this.avatar$ = this.dataSource.connect().pipe(
      concatMap((data) => (data === null ? this.dataSource.loadUser() : of(data))),
      map((user: IUser) => user.avatar),
      shareReplay(1),
    );
    this.country = CountryMap[localStorage.getItem('country')];
    this.countryOptions = CountryMap;
    this.isLogin$ = this.auth.isLogin().pipe(shareReplay(1));
    this.isLoginPath = location.pathname === '/login';
    this.isManager$ = this.dataSource.connect().pipe(
      filter((data) => data !== null),
      map((user: IUser) => user.isManager),
      shareReplay(1),
    );
    this.isWeb$ = this.rwd.isWeb.pipe(shareReplay(1));
    this.screenTop = location.pathname.substring(3) === '/';
  }
  ngOnChanges(): void {
    this.onActivate();
  }
  setCountry(option: { key: string; value: any }) {
    localStorage.setItem('country', option.key.toUpperCase());
    localStorage.setItem('lang', option.value.lang);
    location.reload();
  }
  onActivate(componentRef?: any) {
    this.isLoginPath = location.pathname === '/login';
    this.navbarBgColor = this.screenTop ? 'rgba(0, 0, 0, 0.5)' : '#fff';
    this.navbarBrandImg = `assets/images/${this.screenTop ? 'logo_white.png' : 'logo.png'}`;
    this.navbarFontColor = this.screenTop ? '#fff' : '#000';
    if (componentRef && componentRef.showLoginModal) {
      componentRef.showLoginModal.subscribe(
        (isModalVisible: boolean) => (this.isModalVisible = isModalVisible),
      );
    }
    if (componentRef && componentRef.reload) {
      componentRef.reload.subscribe((reload: boolean) => (this.reload = reload));
    }
    this.reload = false;
  }
  logout(): void {
    this.auth.logout();
    if (this.reload) {
      location.reload();
    }
  }
}
