import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'video' })
export class VideoPipe implements PipeTransform {
  constructor() {}
  transform(data: string): string {
    return data.includes('youtu.be')
      ? `https://www.youtube.com/embed/${data.split(/\//g)[3]}`
      : data.includes('youtube')
      ? `https://www.youtube.com/embed/${data.split(/v\=|\&/g)[1]}`
      : data.includes('facebook')
      ? `https://www.facebook.com/plugins/video.php?href=${data}`
      : '';
  }
}
