import { NzMessageService } from 'ng-zorro-antd/message';
import { AuthService } from 'projects/tools/src/lib/services/auth.service';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap, take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private auth: AuthService,
    private message: NzMessageService,
    private router: Router,
    private translate: TranslateService,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((req: any) => {
        if (req.body && req.body.status >= 400) {
          throw new HttpErrorResponse({
            error: req.body,
            headers: req.headers,
            status: req.body.status,
            statusText: req.body.statusText,
            url: req.url,
          });
        }
      }),
      catchError((httpError: HttpErrorResponse) => {
        const { message: key = 'Error.server', translate: interpolateParams } = httpError.error;
        switch (httpError.status) {
          case 401:
            if (location.pathname !== '/confirm-email') {
              this.auth.logout();
              this.router.navigateByUrl('/login');
            }
            break;
        }
        this.translate
          .get(key.split(','), interpolateParams)
          .pipe(take(1))
          .subscribe((res) => this.message.error(Object.values(res).join('')));
        return throwError(httpError);
      }),
    );
  }
}
