import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';

@Component({
  selector: 'app-card-input',
  template: `
    <input
      #CardInput
      class="text-center"
      maxlength="4"
      nz-input
      placeholder="0000"
      (change)="setCard($event, index)"
      (keyup)="nextInput($event, index)"
      [disabled]="isLoading"
      *ngFor="let index of [0, 1, 2, 3]"
    />
  `,
  styles: [
    `
      input {
        width: 24%;
        border: none;
        margin: 2px;
      }
    `,
  ],
})
export class CardInputComponent implements OnInit {
  @ViewChildren('CardInput') inputList: QueryList<any>;
  @Input() isLoading: boolean;
  @Output() inputChange: EventEmitter<string> = new EventEmitter<string>();
  private card = ['', '', '', ''];
  constructor() {}

  ngOnInit(): void {}
  nextInput = (event: any, index: number): void => {
    if (index !== 3) {
      const nextInput = event.srcElement.nextElementSibling;
      if (event.target.value.length === 4 && nextInput) {
        nextInput.focus();
      }
    }
  };
  setCard(event: any, index: number) {
    this.card[index] = event.target.value;
    this.inputChange.emit(this.card.join(''));
  }
  clearCard(): void {
    this.inputList.map((tpl: ElementRef) => (tpl.nativeElement.value = ''));
  }
}
