import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(data: string, type: string = 'url') {
    switch (type) {
      case 'html':
        return this.sanitizer.bypassSecurityTrustHtml(data);
      case 'url':
        return this.sanitizer.bypassSecurityTrustResourceUrl(data);
      case 'style':
        return this.sanitizer.bypassSecurityTrustStyle(data);
    }
  }
}
