export const MetaData = [
  {
    meta: {
      description:
        'FlipRobot翻轉機器人由課程設計出發，發展出完整的STEAM素養學習架構，清楚的讓學生透過AI與機器人專題，學習21世紀關鍵能力。研發團隊由經驗豐富的教師組成，發展包括機器人教具、STEAM素養教材、師培課程…等，完整的教育解決方案。',
    },
    path: '/',
    title: '翻轉機器人',
  },
  {
    meta: {
      description:
        '2014年Actura璞學智慧在澳洲雪梨成立，以翻轉教育為教育理念，提供領先全球的校內及校外STEAM解決方案。在校內，FlipRobot翻轉機器人提供了一個最佳AI機器人STEAM教育解決方案。',
    },
    path: '/about-us',
    title: '關於我們',
  },
  {
    path: '/confirm-email',
    title: '確認信箱',
  },
  {
    meta: {
      description:
        '歡迎聯繫FlipRobot翻轉機器人，如果您有任何指教或合作提案，歡迎利用線上表單與我們聯繫，我們將於工作日盡快回覆您的詢問。',
    },
    path: '/contact-us',
    title: '聯絡我們',
  },
  {
    meta: {
      description:
        '教學的強力推手，發展完整的雲端學習系統，提供教師豐富的跨領域課程主題教材、教學資源下載及教學知識庫。讓STEAM機器人素養教學變得更容易。',
    },
    path: '/courses',
    title: '翻轉課程',
  },
  {
    path: '/login',
    title: '登入',
  },
  {
    path: '/player/projects',
    title: '小專題',
  },
  {
    path: '/reset-password',
    title: '重設密碼',
  },
  {
    meta: {
      description:
        '針對各學齡開發不同階段的運算思維工具，例如:FlipAiot、FlipCode、FlipRAS、FlipDuino，FlipRobot相信唯有從小紮根、循序漸進，AI教育才能滲入每個細胞思維。',
    },
    path: '/tools',
    title: '翻轉工具',
  },
  {
    meta: {
      description:
        '邁向素養教室的前哨站，一體式STEAM素養機器人師培課程，提供完整學期的授課投影片、教師手冊、機器人，讓不同領域的老師也能輕鬆上手跨領域素養課程。',
    },
    path: '/teacher/training',
    title: '師培中心',
  },
  {
    path: '/unlock-card',
    title: '解鎖課程',
  },
  {
    path: '/user',
    title: '個人資訊',
  },
  {
    path: '/user/setting',
    title: '帳號設置',
  },
  {
    path: '/user/cards',
    title: '序號管理',
  },
  {
    path: '/user/projects',
    title: '專題管理',
  },
  {
    path: '/404',
    title: '404',
  },
];
