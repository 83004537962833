import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { CountryMap } from '../pipes/country.pipe';

@Injectable({
  providedIn: 'root',
})
export class LangService {
  language$ = new ReplaySubject<LangChangeEvent>(1);
  translate = this.translateService;
  constructor(private translateService: TranslateService) {}
  setInitState() {
    this.translateService.addLangs(['en', 'zh-tw']);
    let lang =
      localStorage.getItem('lang') ?? this.translate.getBrowserCultureLang().toLowerCase();
    if (lang !== 'en' && lang !== 'zh-tw') {
      lang = 'en';
    }
    const country = localStorage.getItem('country') || 'TW';
    this.setLang(lang);
    this.setCountry(country);
  }
  setLang(lang: string) {
    localStorage.setItem('lang', lang);
    this.translateService.onLangChange
      .pipe(take(1))
      .subscribe((result) => this.language$.next(result));
    this.translateService.use(lang);
  }
  setCountry(country: string) {
    localStorage.setItem('country', country);
    // this.setLang(CountryMap[country].lang);
  }
}
