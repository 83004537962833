import { AntdModule } from 'projects/tools/src/lib/antd.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { CardGroupComponent } from './components/card-group/card-group.component';
import { CardInputComponent } from './components/card-input/card-input.component';
import { FacebookComponent } from './components/facebook/facebook.component';
import { GoogleComponent } from './components/google/google.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { RedirectToBlogComponent } from './components/redirect-to-blog/redirect-to-blog.component';
import { QrcodeWrapperComponent } from './components/qrcode-wrapper/qrcode-wrapper.component';
import { LangSelectComponent } from './components/lang-select/lang-select.component';

@NgModule({
  declarations: [
    CardGroupComponent,
    CardInputComponent,
    FacebookComponent,
    GoogleComponent,
    NotFoundComponent,
    RedirectToBlogComponent,
    QrcodeWrapperComponent,
    LangSelectComponent,
  ],
  imports: [CommonModule, AntdModule, RouterModule, TranslateModule],
  exports: [
    CardGroupComponent,
    CardInputComponent,
    FacebookComponent,
    GoogleComponent,
    NotFoundComponent,
    RedirectToBlogComponent,
    QrcodeWrapperComponent,
    LangSelectComponent
  ],
})
export class SharedModule {}
