import { throttleTime, distinctUntilChanged } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, fromEvent, Observable } from 'rxjs';
import { RwdService } from 'projects/tools/src/lib/services';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.less'],
})
export class HomeComponent implements OnInit, OnDestroy {
  isHover = Array(7).map(() => false);
  selected = 0;
  screenTop = true;
  scrolled$: Subscription;
  path = 'assets/images/page/home';
  isWeb$: Observable<boolean>;
  constructor(private rwd: RwdService) {}
  ngOnInit(): void {
    this.scrolled$ = fromEvent(window, 'scroll')
      .pipe(throttleTime(25), distinctUntilChanged())
      .subscribe(() => (this.screenTop = window.pageYOffset < 100));
    this.isWeb$ = this.rwd.isWeb;
  }
  ngOnDestroy(): void {
    this.scrolled$.unsubscribe();
  }
  carouselBgImg = (index: number) =>
    `linear-gradient(to right, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.2) 50%), url(/${
      this.path
    }/carousel/${index + 1}.jpg)`;
  valueImg = (index: number, state: string) => `${this.path}/core/${state}/${index + 1}.png`;
  serviceBgImg = (index: number) => `${this.path}/service/${index + 1}.jpg`;
}
