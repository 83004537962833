<div class="bg-fill bg-light fx-col fx-center">
  <div class="form-wrapper fx-col">
    <h4 class="text-center">輸入課程序號</h4>
    <hr class="form-divider" />
    <p class="text-start">
      如果您已經有FlipRobot的課程解鎖序號，請於下方輸入。如果沒有請跳過此步驟。
    </p>
    <app-card-input
      class="space-item fx-row fx-center"
      (inputChange)="onCardChange($event)"
    ></app-card-input>
    <button
      class="form-btn space-item"
      nz-button
      nzType="primary"
      [nzLoading]="isLoading"
      (click)="submitForm()"
    >
      確認解鎖
    </button>
    <a class="space-item" nz-button nzType="link" routerLink="/"> 跳過此步驟 </a>
  </div>
</div>
