import { catchError } from 'rxjs/operators';
import { environment } from 'projects/fliprobot/src/environments/environment';
import { HttpClient, HttpParams, HttpBackend } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  private url = environment.backend;
  private httpWithoutInterceptor: HttpClient;
  constructor(private http: HttpClient, private httpBackend: HttpBackend) {
    this.httpWithoutInterceptor = new HttpClient(httpBackend);
  }
  // httpClient API method
  get(path: string, params?: HttpParams): Observable<any> {
    return this.http
      .get(this.url.concat(path), { params })
      .pipe(catchError((err) => throwError(err)));
  }
  post(path: string, body: any = {}, options = {}): Observable<any> {
    return this.http
      .post(this.url.concat(path), body, options)
      .pipe(catchError((err) => throwError(err)));
  }
  patch(path: string, body = {}): Observable<any> {
    return this.http.patch(this.url.concat(path), body).pipe(catchError((err) => throwError(err)));
  }
  put(path: string, body = {}): Observable<any> {
    return this.http.put(this.url.concat(path), body).pipe(catchError((err) => throwError(err)));
  }
  delete(path: string, options = {}): Observable<any> {
    return this.http
      .delete(this.url.concat(path), options)
      .pipe(catchError((err) => throwError(err)));
  }
  // httpBackend API method without the interceptor
  _get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.httpWithoutInterceptor
      .get(this.url.concat(path), { params })
      .pipe(catchError((err) => throwError(err)));
  }
  _post(path: string, body = {}, options = {}): Observable<any> {
    return this.httpWithoutInterceptor
      .post(this.url.concat(path), body, options)
      .pipe(catchError((err) => throwError(err)));
  }
  _patch(path: string, body = {}): Observable<any> {
    return this.httpWithoutInterceptor
      .patch(this.url.concat(path), body)
      .pipe(catchError((err) => throwError(err)));
  }
  _put(path: string, body = {}): Observable<any> {
    return this.httpWithoutInterceptor
      .put(this.url.concat(path), body)
      .pipe(catchError((err) => throwError(err)));
  }
  _delete(path: string): Observable<any> {
    return this.httpWithoutInterceptor
      .delete(this.url.concat(path))
      .pipe(catchError((err) => throwError(err)));
  }
}
