import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { RwdService } from 'projects/tools/src/lib/services';
import { shareReplay, takeUntil } from 'rxjs/operators';
import { Setting } from './download-btn/download-btn.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-flip-tools',
  templateUrl: './flip-tools.component.html',
  styleUrls: ['./flip-tools.component.less'],
})
export class FlipToolsComponent implements OnInit, OnDestroy, AfterViewInit {
  isWeb$: Observable<boolean>;
  destroy$ = new Subject();
  toolsMap: { [key: string]: Array<Setting> } = {
    flipcode: [
      {
        checkDownloadAccess: true,
        downloadUrl: 'https://assets.fliprobot.com/software/flipcode/FlipCode+Setup+2.7.0.exe',
        translateName: 'Fliptool.btn.windows',
        isDisabled: true,
      },
      {
        checkDownloadAccess: true,
        downloadUrl: 'https://assets.fliprobot.com/software/flipcode/FlipCode-2.7.0.dmg',
        translateName: 'Fliptool.btn.mac',
        isDisabled: true,
      },
      {
        href: 'https://apps.apple.com/app/id1443474045',
        translateName: 'Fliptool.btn.ios',
      },
      {
        href: 'https://code.fliprobot.com',
        translateName: 'Fliptool.btn.online',
      },
      {
        href: 'https://tools.fliprobot.com',
        translateName: 'Fliptool.btn.online_2',
      },
    ],
    flipras: [
      {
        checkDownloadAccess: true,
        downloadUrl: 'https://assets.fliprobot.com/software/flipras/FlipRAS+Setup+1.2.0.exe',
        translateName: 'Fliptool.btn.windows',
        isDisabled: true,
      },
      {
        checkDownloadAccess: true,
        downloadUrl: 'https://assets.fliprobot.com/software/flipras/FlipRAS-1.2.0.dmg',
        translateName: 'Fliptool.btn.mac',
        isDisabled: true,
      },
      {
        href: 'https://apps.apple.com/app/id1481079129',
        translateName: 'Fliptool.btn.ios',
      },
      {
        href: 'https://ras.fliprobot.com/',
        translateName: 'Fliptool.btn.online',
      },
    ],
    flipduino: [
      {
        href: 'https://fliprobot-assets.s3.cn-north-1.amazonaws.com.cn/software/flipduino/FlipDuino+1.0.0.exe',
        translateName: 'Fliptool.btn.windows',
      },
      {
        href: 'https://fliprobot-assets.s3.cn-north-1.amazonaws.com.cn/software/flipduino/FlipDuino+1.0.0.dmg',
        translateName: 'Fliptool.btn.mac',
      },
    ],
    flipaiot: [
      {
        checkDownloadAccess: true,
        downloadUrl: 'https://assets.fliprobot.com/software/flipaiot/FlipAIoT+Setup+1.0.0.exe',
        translateName: 'Fliptool.btn.windows',
        isDisabled: true,
      },
      {
        hasAccessToken: true,
        href: 'https://aiot.fliprobot.com',
        translateName: 'Fliptool.btn.online',
      },
    ],
  };
  constructor(private rwd: RwdService, private route: ActivatedRoute) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  ngOnInit(): void {
    this.isWeb$ = this.rwd.isWeb.pipe(shareReplay(1));
  }
  ngAfterViewInit(): void {
    this.route.fragment.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      const el = document.getElementById(data);
      if (data && el) {
        el.scrollIntoView({ behavior: 'smooth' });
      }
    });
  }
}
