<nz-affix>
  <header class="navbar-header" [style.background-color]="navbarBgColor">
    <div [ngClass]="(isWeb$ | async) ? 'container' : ''">
      <div class="fx-row fx-center justify-between">
        <a class="navbar-brand" routerLink="/">
          <picture>
            <img class="img-fluid" [src]="navbarBrandImg" />
          </picture>
        </a>
        <nav class="navbar-nav">
          <ul class="align-center fx-row no-margin no-padding">
            <ng-container *ngIf="isWeb$ | async">
              <ng-container *ngIf="isLogin$ | async">
                <li
                  *ngIf="isManager$ | async"
                  class="align-center fx-row nav-item"
                  [ngClass]="!(isWeb$ | async) ? '' : 'space-item'"
                >
                  <a
                    [routerLink]="['/admin']"
                    [style.color]="(isWeb$ | async) ? navbarFontColor : '#000'"
                  >
                    Admin
                  </a>
                </li>
              </ng-container>
              <li
                class="align-center fx-row nav-item"
                [ngClass]="!(isWeb$ | async) ? '' : 'space-item'"
                *ngFor="let navItem of navItems"
              >
                <div *ngTemplateOutlet="NavbarNav; context: { navItem: navItem }"></div>
              </li>
              <li
                class="nav-item fx-row align-center"
                [ngClass]="!(isWeb$ | async) ? '' : 'space-item'"
                (click)="isMenuVisible = !isMenuVisible"
              >
                <a
                  class="menu-item"
                  [routerLink]="
                    pathname.includes('/courses/preparation') ? pathname : ['/courses/preparation']
                  "
                  [style.color]="(isWeb$ | async) ? navbarFontColor : '#000'"
                >
                  <i *ngIf="!(isWeb$ | async)" nz-icon nzTheme="fill" [nzType]="'folder-open'"></i>
                  {{ 'Shared.navbar.teaching.main' | translate }}
                </a>
              </li>
            </ng-container>
            <ng-container *ngIf="isLogin$ | async; else SignIn">
              <li
                class="nav-item space-item"
                nz-dropdown
                [nzDropdownMenu]="UserDropdownMenu"
                [nzPlacement]="'bottomCenter'"
              >
                <nz-avatar
                  [nzSize]="32"
                  [nzSrc]="(avatar$ | async) || 'assets/images/user.png'"
                  class="bg-primary no-margin"
                ></nz-avatar>
              </li>
              <nz-dropdown-menu #UserDropdownMenu="nzDropdownMenu">
                <ul nz-menu nzMode="horizontal" [nzSelectable]="false">
                  <li *ngFor="let menuItem of userMenuItems" class="no-padding" nz-menu-item>
                    <a
                      *ngIf="menuItem.link"
                      [routerLink]="[menuItem.link]"
                      class="no-margin"
                      style="width: 100%; height: 100%; padding: 10px 15px"
                    >
                      <i [nzType]="menuItem.icon" nz-icon style="margin-right: 10px"></i>
                      {{ 'Shared.navbar.' + menuItem.label | translate }}
                    </a>
                  </li>
                  <hr style="margin: 8px 15px" />
                  <li class="no-padding" nz-menu-item style="width: 170px; height: 40px">
                    <a
                      (click)="logout()"
                      class="no-margin"
                      style="width: 100%; height: 100%; padding: 10px 15px"
                    >
                      <strong>
                        <i nzType="logout" nz-icon style="margin-right: 10px"></i>
                        {{ 'Shared.navbar.sign_out' | translate }}
                      </strong>
                    </a>
                  </li>
                </ul>
              </nz-dropdown-menu>
            </ng-container>
            <ng-template #SignIn>
              <li class="nav-item space-item no-border">
                <button
                  class="bg-primary text-white fx-row fx-center no-border"
                  style="padding: 6px 15px; border-radius: 3px; line-height: 24px"
                  [disabled]="isLoginPath"
                  (click)="isModalVisible = true"
                >
                  {{ 'Shared.navbar.sign_in' | translate }}
                </button>
              </li>
            </ng-template>
            <!-- <li
              class="nav-item space-item"
              nz-dropdown
              [nzDropdownMenu]="CountryDropdownMenu"
              [nzPlacement]="'bottomCenter'"
            >
              <a [style.color]="navbarFontColor" (click)="isMenuVisible = false">
                <nz-avatar [nzSrc]="country.icon" [nzSize]="32"></nz-avatar>
                <span
                  [innerText]="(isWeb$ | async) ? country.label : (country.value | uppercase)"
                ></span>
              </a>
            </li> -->
            <nz-dropdown-menu #CountryDropdownMenu="nzDropdownMenu">
              <ul nz-menu nzMode="horizontal" [nzSelectable]="false">
                <li nz-menu-item style="height: 40px; padding: 5px 15px">
                  Choose your country or region
                </li>
                <li
                  nz-menu-item
                  *ngFor="let option of countryOptions | keyvalue"
                  (click)="setCountry(option)"
                  style="padding: 5px 15px"
                >
                  <nz-avatar [nzSrc]="option.value.icon"></nz-avatar>
                  <span>{{ option.value.label }}</span>
                </li>
              </ul>
            </nz-dropdown-menu>
            <app-lang-select [color]="navbarFontColor"></app-lang-select>
            <li *ngIf="!(isWeb$ | async)">
              <button
                nz-button
                nzType="link"
                nz-dropdown
                [nzDropdownMenu]="MobileMenu"
                [nzOverlayStyle]="mobileDropdownStyle"
                [nzPlacement]="'bottomRight'"
                [(nzVisible)]="isMenuVisible"
                (click)="isMenuVisible = !isMenuVisible"
                style="width: 50px"
              >
                <i class="no-margin" [style.color]="navbarFontColor" nz-icon nzType="menu"></i>
              </button>
            </li>
            <nz-dropdown-menu #MobileMenu="nzDropdownMenu">
              <ul class="navbar-nav" nz-menu nzMode="inline">
                <li
                  class="nav-item no-border"
                  nz-menu-item
                  *ngFor="let navItem of navItems"
                  (click)="isMenuVisible = !isMenuVisible"
                >
                  <div *ngTemplateOutlet="NavbarNav; context: { navItem: navItem }"></div>
                </li>
                <li
                  class="nav-item no-border"
                  nz-menu-item
                  (click)="isMenuVisible = !isMenuVisible"
                >
                  <a
                    [routerLink]="
                      pathname.includes('/courses/preparation')
                        ? pathname
                        : ['/courses/preparation']
                    "
                    [style.color]="(isWeb$ | async) ? navbarFontColor : '#000'"
                  >
                    <i
                      *ngIf="!(isWeb$ | async)"
                      nz-icon
                      nzTheme="fill"
                      [nzType]="'folder-open'"
                    ></i>
                    {{ 'Shared.navbar.teaching.main' | translate }}
                  </a>
                </li>
                <ng-container *ngIf="isLogin$ | async">
                  <hr />
                  <li *ngFor="let menuItem of userMenuItems" class="no-padding" nz-menu-item>
                    <a
                      *ngIf="menuItem.link"
                      [routerLink]="[menuItem.link]"
                      class="no-margin"
                      style="width: 100%; height: 100%; padding: 9px 24px"
                    >
                      <i [nzType]="menuItem.icon" nz-icon style="margin-right: 10px"></i>
                      {{ 'Shared.navbar.' + menuItem.label | translate }}
                    </a>
                  </li>
                  <hr />
                  <li class="no-padding" nz-menu-item>
                    <a
                      class="no-margin"
                      style="width: 100%; height: 100%; padding: 9px 24px"
                      (click)="logout()"
                    >
                      <strong>
                        <i nzType="logout" nz-icon style="margin-right: 10px"></i>
                        {{ 'Shared.navbar.sign_out' | translate }}
                      </strong>
                    </a>
                  </li>
                </ng-container>
              </ul>
            </nz-dropdown-menu>
          </ul>
        </nav>
      </div>
    </div>
  </header>
</nz-affix>
<ng-template #NavbarNav let-navItem="navItem">
  <a
    class="menu-item"
    [routerLink]="[navItem.link]"
    [style.color]="(isWeb$ | async) ? navbarFontColor : '#000'"
    *ngIf="navItem.link"
  >
    <i *ngIf="!(isWeb$ | async)" nz-icon nzTheme="fill" [nzType]="navItem.icon"></i>
    {{ 'Shared.navbar.' + navItem.label | translate }}
  </a>
  <a
    class="menu-item"
    (click)="$event.stopPropagation()"
    [href]="navItem.href"
    [style.color]="(isWeb$ | async) ? navbarFontColor : '#000'"
    *ngIf="navItem.href"
    rel="noopener noreferrer"
    target="_blank"
  >
    <i *ngIf="!(isWeb$ | async)" nz-icon nzTheme="fill" [nzType]="navItem.icon"></i>
    {{ 'Shared.navbar.' + navItem.label | translate }}
  </a>
</ng-template>
<nz-modal
  [(nzVisible)]="isModalVisible"
  [nzTitle]="null"
  [nzFooter]="null"
  [nzWidth]="360"
  (nzOnCancel)="isModalVisible = false"
>
  <ng-container *ngIf="isModalVisible">
    <app-login [isModal]="true" (closeModel)="isModalVisible = false"></app-login>
  </ng-container>
</nz-modal>
<router-outlet (activate)="onActivate($event)"></router-outlet>
