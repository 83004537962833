<main>
  <section class="banner fx-col fx-center">
    <picture>
      <img class="img-fluid" src="assets/images/page/tools/logo.png" />
    </picture>
  </section>
  <section class="bg-light">
    <div class="container">
      <section
        class="bg-white section"
        [id]="tool.type"
        *ngFor="let tool of 'Fliptool.tools' | translate; let i = index"
      >
        <div class="fx-row fx-wrap justify-between">
          <div class="fx-col" [style.flex-basis]="(isWeb$ | async) ? '75%' : '100%'">
            <h4 class="font-bold text-primary">{{ tool.heading }}</h4>
            <div [innerHTML]="tool.paragraph | safe: 'html'"></div>
          </div>
          <div
            [ngClass]="(isWeb$ | async) ? 'fx-col align-center' : 'fx-row justify-between'"
            [style.flex-basis]="(isWeb$ | async) ? '25%' : '100%'"
          >
            <picture>
              <img class="img-fluid" [src]="tool.icon" />
            </picture>
            <div class="fx-col">
              <ng-container *ngFor="let btn of toolsMap[tool.type]">
                <app-download-btn [setting]="btn" [type]="tool.type"></app-download-btn>
              </ng-container>
            </div>
          </div>
        </div>
      </section>
    </div>
  </section>
</main>
<app-footer></app-footer>
