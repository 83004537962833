<footer>
  <div *ngIf="'Shared.navbar' | translate as nav" class="container">
    <div [ngClass]="(isMobile$ | async) ? 'fx-col' : 'fx-row'" class="justify-between">
      <nav [ngClass]="(isMobile$ | async) ? 'fx-col' : 'fx-row'" class="justify-between">
        <ul class="fx-col">
          <li class="space-item text-dark">{{ nav.main }}</li>
          <li class="space-item">
            <a routerLink="/about-us">{{ nav.about }}</a>
          </li>
          <li class="space-item">
            <a routerLink="/contact-us">{{ nav.contact }}</a>
          </li>
          <li class="space-item">
            <a href="https://blog.fliprobot.com/" target="_blank" rel="noopener noreferrer"
              >{{ nav.blog }}
            </a>
          </li>
          <hr *ngIf="isMobile$ | async" />
        </ul>
        <ul class="fx-col">
          <li class="space-item text-dark">{{ nav.course.main }}</li>
          <li class="space-item">
            <a routerLink="/courses">{{ nav.course.explore }}</a>
          </li>
          <li class="space-item">
            <a routerLink="/courses" [state]="{ tab: 1 }">
              {{ nav.course.structure }}
            </a>
          </li>
          <li class="space-item">
            <a routerLink="/courses" [state]="{ tab: 2 }">
              {{ nav.course.school_tools }}
            </a>
          </li>
          <hr *ngIf="isMobile$ | async" />
        </ul>
        <ul class="fx-col">
          <li class="space-item text-dark">{{ nav.training.main }}</li>
          <li class="space-item">
            <a routerLink="/teacher/training">{{ nav.training.center }}</a>
          </li>
          <hr *ngIf="isMobile$ | async" />
        </ul>
        <ul class="fx-col">
          <li class="space-item text-dark">{{ nav.player.main }}</li>
          <li class="space-item">
            <a routerLink="/player/projects">
              {{ nav.player.project }}
            </a>
          </li>
          <li class="space-item">
            <a href="https://fliprobot.gitbook.io/knowledge-base/" target="_blank">
              {{ nav.player.knowledge_base }}
            </a>
          </li>
          <hr *ngIf="isMobile$ | async" />
        </ul>
        <ul class="fx-col">
          <li class="space-item text-dark">{{ nav.teaching.main }}</li>
          <li class="space-item">
            <a routerLink="/courses/preparation">
              {{ nav.teaching.preparation }}
            </a>
          </li>
          <hr *ngIf="isMobile$ | async" />
        </ul>
        <ul class="fx-col">
          <li class="space-item text-dark">{{ nav.tools }}</li>
          <li class="space-item"><a routerLink="/tools" fragment="flipcode">FlipCode</a></li>
          <li class="space-item"><a routerLink="/tools" fragment="flipras">FlipRAS</a></li>
          <li class="space-item"><a routerLink="/tools" fragment="flipduino">FlipDuino</a></li>
          <li class="space-item"><a routerLink="/tools" fragment="flipaiot">FlipAIoT</a></li>
          <hr *ngIf="isMobile$ | async" />
        </ul>
      </nav>
      <div class="fx-row align-start justify-between" style="max-width: 200px; padding: 10px 0">
        <div class="icon-container">
          <a href="https://lin.ee/9ZKIHIA" target="_blank">
            <picture>
              <img src="/assets/images/line.png" alt="" />
            </picture>
          </a>
        </div>
        <div class="icon-container">
          <a href="https://www.facebook.com/fliprobot" target="_blank">
            <picture>
              <img src="/assets/images/facebook.png" alt="" />
            </picture>
          </a>
        </div>
        <div class="icon-container">
          <a href="https://www.youtube.com/channel/UCNtTtexZSexmNW5nTCUp2Ng/videos" target="_blank">
            <picture>
              <img src="/assets/images/youtube.png" alt="" />
            </picture>
          </a>
        </div>
      </div>
      <hr *ngIf="isMobile$ | async" />
    </div>
    <hr *ngIf="!(isMobile$ | async)" style="width: 100%; border-width: 0.5px; margin: 15px 0" />
    <div [ngClass]="(isMobile$ | async) ? 'fx-col' : 'fx-row'" class="align-center justify-between">
      <p [style.margin-bottom]="(isMobile$ | async) ? '10px' : '0px'" class="text-white">
        Copyright &copy; {{ year }} Actura Co.,Ltd. All rights reserved.
      </p>
      <div [style.margin-bottom]="(isMobile$ | async) ? '10px' : '0px'" class="fx-row legal">
        <a
          class="space-item"
          style="min-width: 60px"
          target="_blank"
          [href]="baseUrl + '/assets/static/privacy.html'"
          >{{ nav.privacy }}
        </a>
        <a
          class="space-item"
          style="min-width: 48px"
          target="_blank"
          [href]="baseUrl + '/assets/static/warranty.html'"
          >{{ nav.warranty }}
        </a>
        <a
          class="space-item"
          style="min-width: 48px"
          target="_blank"
          [href]="baseUrl + '/assets/static/site.html'"
          >{{ nav.use_terms }}
        </a>
      </div>
      <a nz-dropdown [nzDropdownMenu]="CountryMenu" [nzPlacement]="'topCenter'">
        <nz-avatar [nzSrc]="country.icon" [nzSize]="20"></nz-avatar>
        <span [innerText]="country.label"></span>
      </a>
      <nz-dropdown-menu #CountryMenu="nzDropdownMenu">
        <ul nz-menu nzMode="horizontal" [nzSelectable]="false">
          <li nz-menu-item>Choose your country or region</li>
          <li
            nz-menu-item
            *ngFor="let option of countryOptions | keyvalue"
            (click)="setCountry(option)"
          >
            <nz-avatar [nzSrc]="option.value.icon"></nz-avatar>
            <span>{{ option.value.label }}</span>
          </li>
        </ul>
      </nz-dropdown-menu>
    </div>
  </div>
</footer>
