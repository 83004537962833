import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-qrcode-wrapper',
  template: `
    <div
      class="qrcode-container fx-col fx-center"
      [style]="{ 'background-color': backgroundColor }"
    >
      <picture>
        <img [style]="{ 'max-width': width, height: width }" [src]="imgSrc" [alt]="text" />
      </picture>
      <span nz-typography nzEllipsis nzEllipsisRows="1">{{ text }}</span>
    </div>
  `,
  styles: [
    `
      .qrcode-container {
        width: 160px;
        border-radius: 18px;
        padding: 10px;
      }
      .qrcode-container span {
        color: #fff;
      }
    `,
  ],
})
export class QrcodeWrapperComponent implements OnInit {
  @Input() text: string;
  @Input() imgSrc: string;
  @Input() width = '140px';
  @Input() backgroundColor = 'rgba(35, 182, 188, 0.7)';
  constructor() {}

  ngOnInit(): void {}
}
