import { HttpService } from 'projects/tools/src/lib/services';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  UrlTree,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard implements CanActivate {
  constructor(private http: HttpService, private router: Router) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.http.post('/admin/login').pipe(
      map((data: { isManager: boolean }) => data.isManager),
      catchError((err) => {
        this.router.navigateByUrl('/');
        return throwError(err);
      }),
    );
  }
}
