import { RwdService, LangService } from 'projects/tools/src/lib/services';
import { Component, OnInit } from '@angular/core';
import { environment } from 'projects/fliprobot/src/environments/environment';
import { map, shareReplay } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CountryMap } from 'projects/tools/src/lib/pipes/country.pipe';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.less'],
})
export class FooterComponent implements OnInit {
  baseUrl = environment.baseUrl;
  country: { icon: string; label: string; lang: string; value: string };
  countryOptions: any;
  isMobile$: Observable<boolean>;
  lang$: Observable<string>;
  year: number;
  constructor(private rwd: RwdService, public langService: LangService) {}

  ngOnInit(): void {
    this.country = CountryMap[localStorage.getItem('country')];
    this.countryOptions = CountryMap;
    this.isMobile$ = this.rwd.isMobile.pipe(shareReplay(1));
    this.lang$ = this.langService.language$.pipe(map((data) => data.lang));
    this.year = new Date().getFullYear();
  }
  setCountry(option: { key: string; value: any }) {
    localStorage.setItem('country', option.key);
    localStorage.setItem('lang', option.value.lang);
    location.reload();
  }
}
