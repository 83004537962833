import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

export const enum BREAKPOINT {
  XS_MAX = '(max-width: 575px)',
  SM_MIN = '(min-width: 576px)',
  SM_MAX = '(max-width: 767px)',
  MD_MIN = '(min-width: 768px)',
  MD_MAX = '(max-width: 991px)',
  LG_MIN = '(min-width: 992px)',
  LG_MAX = '(max-width: 1199px)',
  XL_MIN = '(min-width: 1200px)',
  LANDSCAPE = '(orientation: landscape)',
  PORTRAIT = '(orientation: portrait)',
}
export const DEVICE = {
  XS: `${BREAKPOINT.XS_MAX}`,
  SM: `${BREAKPOINT.SM_MIN} and ${BREAKPOINT.SM_MAX}`,
  MD: `${BREAKPOINT.MD_MIN} and ${BREAKPOINT.MD_MAX}`,
  LG: `${BREAKPOINT.LG_MIN} and ${BREAKPOINT.LG_MAX}`,
  XL: `${BREAKPOINT.XL_MIN}`,
  HANDSET: `${BREAKPOINT.XS_MAX} and ${BREAKPOINT.PORTRAIT}, ${BREAKPOINT.MD_MAX} and ${BREAKPOINT.LANDSCAPE}`,
  HANDSET_PORTRAIT: `${BREAKPOINT.XS_MAX} and ${BREAKPOINT.PORTRAIT}`,
  HANDSET_LANDSCAPE: `${BREAKPOINT.MD_MAX} and ${BREAKPOINT.LANDSCAPE}`,
  TABLET: `${BREAKPOINT.SM_MIN} and ${BREAKPOINT.MD_MAX} and ${BREAKPOINT.PORTRAIT}, ${BREAKPOINT.LG_MIN} and ${BREAKPOINT.LG_MAX} and ${BREAKPOINT.LANDSCAPE}`,
  TABLET_PORTRAIT: `${BREAKPOINT.SM_MIN} and ${BREAKPOINT.MD_MAX} and ${BREAKPOINT.PORTRAIT}`,
  TABLET_LANDSCAPE: `${BREAKPOINT.LG_MIN} and ${BREAKPOINT.LG_MAX} and ${BREAKPOINT.LANDSCAPE}`,
  WEB: `${BREAKPOINT.XL_MIN} and ${BREAKPOINT.LANDSCAPE}`,
};

@Injectable({
  providedIn: 'root',
})
export class RwdService {
  constructor(private breakpointObserver: BreakpointObserver) {}
  get isMobile(): Observable<boolean> {
    return this.breakpointObserver.observe([DEVICE.HANDSET]).pipe(map((data) => data.matches));
  }
  get isTablet(): Observable<boolean> {
    return this.breakpointObserver.observe([DEVICE.TABLET]).pipe(map((data) => data.matches));
  }
  get isWeb(): Observable<boolean> {
    return this.breakpointObserver.observe([DEVICE.WEB]).pipe(map((data) => data.matches));
  }
  breakPointQuery(query: any[]): Observable<boolean> {
    return this.breakpointObserver.observe(query).pipe(map((data) => data.matches));
  }
}
