<main>
  <section class="banner bg fx-row fx-center">
    <h2 class="font-bold text-white">{{ 'About.banner' | translate }}</h2>
  </section>
  <div class="bg fx-row fx-wrap" id="dream-history">
    <section class="fx-col fx-center" data-dream>
      <h3>{{ 'About.dream.heading' | translate }}</h3>
      <p class="h6">{{ 'About.dream.paragraph' | translate }}</p>
    </section>
    <section class="fx-col fx-center" data-history>
      <h3 class="text-white">{{ 'About.history.heading' | translate }}</h3>
      <p class="h6 text-white">{{ 'About.history.paragraph' | translate }}</p>
    </section>
  </div>
  <section id="partner">
    <div class="fx-row fx-wrap">
      <div
        *ngFor="let partner of 'About.partner' | translate"
        class="fx-col fx-center"
        data-partner
      >
        <picture *ngIf="partner.image">
          <img class="img-fluid" [src]="partner.image" />
        </picture>
        <h2 *ngIf="partner.heading" class="text-white">
          {{ partner.heading }}
        </h2>
        <div
          [style.background-color]="partner.bg_color"
          class="fx-col fx-center"
          data-partner-cover
        >
          <h3 class="text-white">{{ partner.cover.heading }}</h3>
          <p class="h6 text-white">{{ partner.cover.paragraph }}</p>
        </div>
      </div>
    </div>
  </section>
  <section class="section" id="paper">
    <div class="container fx-col fx-center">
      <h2 class="text-center">{{ 'About.paper.heading' | translate }}</h2>
      <p class="h6 text-center" style="margin-bottom: 30px; max-width: 900px">
        {{ 'About.paper.paragraph' | translate }}
      </p>
      <nz-collapse nzAccordion [nzBordered]="false">
        <nz-collapse-panel
          *ngFor="let panel of 'About.paper.panel' | translate; let i = index"
          [nzActive]="panel.is_active"
          [nzHeader]="title"
          [nzShowArrow]="false"
          class="bg-white"
        >
          <ng-template #title>
            <div class="fx-row fx-center justify-between">
              <h5 class="text-primary no-margin">{{ panel.heading }}</h5>
              <i class="text-primary" nz-icon nzType="plus"></i>
            </div>
          </ng-template>
          <p
            [innerHTML]="paper"
            *ngFor="let paper of panel.paper"
            class="h6 text-secondary"
            nz-typography
            nzEllipsis
            nzEllipsisRows="4"
          ></p>
        </nz-collapse-panel>
      </nz-collapse>
    </div>
  </section>
  <section class="section" id="patent">
    <div class="container fx-col fx-center">
      <h2 class="text-white">{{ 'About.patent.heading' | translate }}</h2>
      <p class="h6 text-white" style="margin-bottom: 30px">
        {{ 'About.patent.paragraph' | translate }}
      </p>
      <div class="fx-row fx-wrap justify-between">
        <picture *ngFor="let image of 'About.patent.image' | translate">
          <img class="img-fluid" [src]="image" />
        </picture>
      </div>
    </div>
  </section>
  <section class="banner fx-col fx-center" id="cooperation">
    <div class="container">
      <div class="fx-col justify-center">
        <h2 class="text-white" [ngClass]="(isMobile$ | async) ? 'text-center' : ''">
          {{ 'Shared.cooperation.heading' | translate }}
        </h2>
        <p class="h5 text-white">
          {{ 'Shared.cooperation.paragraph' | translate }}
        </p>
        <a
          class="btn btn-outline-primary no-border"
          routerLink="/contact-us"
          [ngClass]="(isMobile$ | async) ? 'align-self-center' : ''"
        >
          {{ 'Shared.btn.contact_us' | translate }}
        </a>
      </div>
    </div>
  </section>
</main>
<app-footer></app-footer>
